import $ from 'jquery';

import PerfectScrollbar from '../../mdb-ecommerce/src/js/vendor/pro/scrollbar';
import './helper/tierPrices';
import { togglePassword } from './helper/Toggle';
import { updateQueryStringParameter, vanillaAjax } from './helper/ajax'
import serializeFormIntoArray from './helper/serializeArray'
import { BootstrapConfirm } from './helper/bs_confirm'
import { Modal } from 'bootstrap'

require('./bootstrap');
require('./mdb');
require('./cookie_consent');

require('../css/main.scss');

// expose $ globally so I can use it in the template
window.$ = $;
window.jQuery = $;

$(document).ready(() => {
  $('[data-toggle="tooltip"]').tooltip({
    template: '<div class="tooltip md-tooltip"><div class="tooltip-arrow md-arrow"></div><div class="tooltip-inner md-inner"></div></div>'
  });
  $('#sylius-cart-button').popover();
  $('.star-rating').mdbRate();
  $('.mdb-select').materialSelect({ visibleOptions: -1 });
  $('.treeview-animated').mdbTreeview();
  $('.datepicker').datepicker({
    inline: false,
    formatSubmit: 'yyyy-mm-dd',
    format: 'dd.mm.yyyy',
    firstDay: 1,
    monthsFull: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    weekdaysFull: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    today: 'Heute',
    clear: 'Leeren',
    close: 'Schließen',
    hiddenName: true
  });
  $('input[type="password"]').togglePassword();

  $('.carousel.carousel-multi-item.v-2 .carousel-item').each(function () {
    let next = $(this).next();
    if (!next.length) {
      next = $(this).siblings(':first');
    }
    next.children(':first-child').clone().appendTo($(this));

    for (let i = 0; i < 4; i++) {
      next = next.next();
      if (!next.length) {
        next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));
    }
  });

  const sideNavButton = $('.button-collapse');
  sideNavButton.sideNav2({
    edge: 'right',
    showOverlay: true,
    onOpen: function () {
      $('.animated-bars').addClass('open');
    },
    onClose: function () {
      $('.animated-bars').removeClass('open');
    },
  });
  const sideNavScrollbar = document.querySelector('.custom-scrollbar');
  if (sideNavScrollbar !== null) {
    const ps = new PerfectScrollbar(sideNavScrollbar);
  }

  $('.mega-menu a[data-toggle="collapse"]').click((e) => {
    e.stopPropagation();
    if ($(e.target).attr('href') === undefined && $(e.target).parent().attr('href') !== undefined) {
      $($(e.target).parent().attr('href')).collapse('toggle');
    } else {
      $($(e.target).attr('href')).collapse('toggle');
    }
  });

  $('.star-rating > i').on('mouseleave', function () {
    const stars = $(this).data('index');
    $('sylius_product_review_rating').find('input').prop('checked', false);
    $(`#sylius_product_review_rating_${stars}`).prop('checked', true);
  });

  // sylius confirmation
  $('[data-requires-confirmation]').on('click', (evt) => {
    evt.preventDefault();

    const actionButton = $(evt.currentTarget);

    if (actionButton.is('a')) {
      $('#confirmation-button').attr('href', actionButton.attr('href'));
    }

    if (actionButton.is('button')) {
      $('#confirmation-button').on('click', (event) => {
        event.preventDefault();

        actionButton.closest('form').submit();
      });
    }

    $('#confirmation-modal').modal('show');
  });

  // sylius checkout icons
  $('ul.stepper > li.completed').each(function () {
    let step = $(this);
    let iconWrapper = step.find('a').children().first();
    iconWrapper.children().last().removeClass().addClass('fas fa-check fa-stack-1x text-success');
    iconWrapper.children().first().remove();
  });

  $('.custom-address-book-select').on('change', function () {
    let selectWrapper = $(this);
    let addressFormSection = selectWrapper.closest('[id^=sylius-]');
    let addressForm = selectWrapper.closest('form.loadable');
    let selectedAddress = selectWrapper.children('option:selected');
    if (selectedAddress.length === 1) {
      addressForm.first().addClass('loading');

      $.each(selectedAddress.first().data(), function (name, value) {
        let currentField = addressFormSection.find(`[name*="[${name}]"]`);
        if (name === 'countryCode') {
          currentField.val(value).change();
        } else {
          currentField.val(value).trigger('change');
        }
      });

      addressForm.first().removeClass('loading');
    }
  });

  $(document).variantTierPrices();

  $('.toggle-read-more').on('click', function (e) {
    e.preventDefault();

    var container = $(this).closest('.card-body');
    var modal = $(`${'<div class="modal" tabindex="-1" role="dialog">'
      + '  <div class="modal-dialog modal-lg" role="document">'
      + '    <div class="modal-content">'
      + '      <div class="modal-header">'
      + `        <h5 class="modal-title">${container.find('.card-title').text()}</h5>`
      + '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">'
      + '          <span aria-hidden="true">&times;</span>'
      + '        </button>'
      + '      </div>'
      + '      <div class="modal-body">'
      + `        <p>${container.find('.read-more-collapsed').html()}</p>`
      + '      </div>'
      + '    </div>'
      + '  </div>'
      + '</div>'}`);

    modal.modal('show');
  });

  $('#checkout_as_guest').on('click', function () {
    $('#choose-checkout-container').addClass('d-none').removeClass('d-flex flex-column');
    $('#sylius_checkout_address_customer_email').parent().detach().insertBefore($('#sylius_checkout_address_billingAddress_phoneNumber').closest('.row'));
    $('#address-container').removeClass('d-none');
  });

  $('form[name="sylius_customer_registration"]').each(function () {
    var form = $(this);
    form.find('#sylius_customer_registration_firstName').on('keypress change', function () {
      form.find('#sylius_customer_registration_defaultAddress_firstName').val($(this).val());
    });
    form.find('#sylius_customer_registration_lastName').on('keypress change', function () {
      form.find('#sylius_customer_registration_defaultAddress_lastName').val($(this).val());
    });
    form.find('#sylius_customer_registration_phoneNumber').on('keypress change', function () {
      form.find('#sylius_customer_registration_defaultAddress_phoneNumber').val($(this).val());
    });
  });

  // toggle input labels for login fields, to prevent strange behavior with chrome autocomplete
  $('label[for="' + $('#sylius_checkout_address_customer_email').attr('id') + '"]').addClass('active');
  $('label[for="' + $('#_username').attr('id') + '"]').addClass('active');
  $('label[for="' + $('#sylius_checkout_address_password').attr('id') + '"]').addClass('active');
  $('label[for="' + $('#_password').attr('id') + '"]').addClass('active');
});

document.addEventListener('DOMContentLoaded', function() {
  /**************************************************************************************************
   *                                      confirm modals                                            *
   **************************************************************************************************/

  let confirmButtons = document.querySelectorAll('[data-bsc-toggle="confirm"]');

  for (let confirmButton of confirmButtons) {
    new BootstrapConfirm(confirmButton);
  }

  /**************************************************************************************************
   *                                      character wizard                                          *
   **************************************************************************************************/

  let wizardForms = document.querySelectorAll('form[name="character_wizard_form"]');

  wizardForms.forEach(function (wizardForm) {
    wizardForm.addEventListener('submit', function (event) {
      event.preventDefault();

      vanillaAjax(
        'POST',
        wizardForm.getAttribute('data-action-confirm'),
        serializeFormIntoArray(wizardForm),
        function (rawData) {
          let data = JSON.parse(rawData);

          let listString = '<ul>';
          data.forEach(function (character) {
            listString += '<li>Buchstabe: <strong>' + character['character'] + '</strong>, Anzahl: ' + character['quantity'] + '</li>';
          });
          listString += '</ul>'

          let modalTemplate = `<div class="modal fade" id="wizard-confirm-modal" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <span id="mySmallModalLabel" class="modal-title">Zusammenfassung</span>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Schließen">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Nachfolgend werden Ihnen die Buchenstaben in passender Menge aus Ihrem angegebenen Schriftzug aufgelistet.
                            Sie können diese unten bestätigen und Ihrem Warenkorb hinzufügen oder zurück und den Schriftzug ändern:
                            ${listString}
                        </div>
                        <div class="modal-footer btn-group p-0">
                            <button class="btn btn-link fs-6 text-decoration-none col-md-6 col-12 m-0 rounded-0 rounded-bottom" data-dismiss="modal">Zurück</button>
                            <button class="btn btn-success fs-6 text-decoration-none col-md-6 col-12 m-0 rounded-0 rounded-bottom" data-trigger="confirm">Bestätigen</button>
                        </div>
                    </div>
                </div>
            </div>
          `;

          let modal = document.createElement('div');
          modal.innerHTML = modalTemplate;
          modal = modal.firstChild;

          modal.querySelectorAll("[data-trigger='confirm']").forEach(function (item) {
            item.addEventListener('click', function () {
              wizardForm.submit();
            });
          });

          document.body.appendChild(modal);
          let modalInstance = new Modal(modal, {backdrop: 'static', keyboard: false});
          modalInstance.show();

          // modal.addEventListener('hidden.bs.modal', function (event) {
          //   modalInstance.modal('dispose');
          //   this.remove();
          // });
        }
      );
    });
  });

  let productAddonButtons = document.querySelectorAll('a.product-addon-submit');

  productAddonButtons.forEach(function (productAddonButton) {
    productAddonButton.addEventListener('click', function (event) {
      event.preventDefault();

      let quantityInput = productAddonButton.closest('.product-addon-wrapper').querySelector('.product-addon-quantity');
      let quantity = 1;

      if (quantityInput && quantityInput.nodeName === 'INPUT') {
        quantity = quantityInput.value;
      } else if (quantityInput && quantityInput.nodeName === 'DIV' && quantityInput.classList.contains('mdb-select')) {
        quantity = $(quantityInput.querySelector('select')).val();
      }

      let newUrl = updateQueryStringParameter(productAddonButton.getAttribute('href'), 'quantity', quantity);

      productAddonButton.setAttribute('href', newUrl);
      window.location.href = newUrl;
    });
  });
});
