/**
 * helper for $.ajax function
 *
 * @param {string} type
 * @param {string} url
 * @param {{}} data
 * @param {Function} success callback for successful ajax request
 * @param {Function} fail callback for failed ajax request
 */
export function vanillaAjax(type, url, data, success, fail = function () {}) {
  let params = null;

  if (type === 'POST') {
    if (typeof data === 'string') {
      params = data;
    } else {
      params = Object.keys(data).map(
        function (k) {
          if (data[k] instanceof Object) {
            return encodeURIComponent(data[k].name) + '=' + encodeURIComponent(data[k].value);
          } else {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
          }
        }
      ).join('&');
    }
  }

  let xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");

  xhr.onreadystatechange = function() {
    if (success instanceof Function && xhr.readyState > 3 && xhr.status === 200) {
      success(xhr.responseText);
    } else if (fail instanceof Function && xhr.readyState > 3 && xhr.status !== 200) {
      fail(xhr.responseText);
    }
  };

  xhr.open(type, url);
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

  if (type === 'POST') {
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(params);
  } else if (type === 'GET') {
    xhr.send();
  }

  return xhr;
}

// helper function: updates the query string of the given uri
export function updateQueryStringParameter(uri, key, value) {
  let anchor = '';
  // keep anchor
  if (uri.indexOf('#') !== -1) {
    anchor = uri.substr(uri.indexOf('#'), uri.length);
    uri = uri.substr(0, uri.indexOf('#'));
  }

  let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  let separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2') + anchor;
  } else {
    return uri + separator + key + "=" + value + anchor;
  }
}

