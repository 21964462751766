import '../../mdb-ecommerce/src/js/free/forms-free';
import '../../mdb-ecommerce/src/js/free/scrolling-navbar';
import '../../mdb-ecommerce/src/js/free/treeview';
import '../../mdb-ecommerce/src/js/free/wow';
import '../../mdb-ecommerce/src/js/pro/buttons';
import '../../mdb-ecommerce/src/js/pro/cards';
import '../../mdb-ecommerce/src/js/pro/character-counter';
import '../../mdb-ecommerce/src/js/pro/chips';
import '../../mdb-ecommerce/src/js/pro/collapsible';
import '../../mdb-ecommerce/src/js/pro/dropdown/dropdown';
import '../../mdb-ecommerce/src/js/pro/dropdown/dropdown-searchable';
import '../../mdb-ecommerce/src/js/pro/file-input';
import '../../mdb-ecommerce/src/js/pro/material-select/material-select';
import '../../mdb-ecommerce/src/js/pro/material-select/material-select-view';
import '../../mdb-ecommerce/src/js/pro/material-select/material-select-view-renderer';
import '../../mdb-ecommerce/src/js/pro/mdb-autocomplete';
import '../../mdb-ecommerce/src/js/pro/picker';
import '../../mdb-ecommerce/src/js/pro/picker-date';
import '../../mdb-ecommerce/src/js/pro/picker-time';
import '../../mdb-ecommerce/src/js/pro/preloading';
import '../../mdb-ecommerce/src/js/pro/range-input';
import '../../mdb-ecommerce/src/js/pro/sidenav';
import '../../mdb-ecommerce/src/js/pro/sidenav2';
import '../../mdb-ecommerce/src/js/pro/smooth-scroll';
import '../../mdb-ecommerce/src/js/pro/sticky';
import '../../mdb-ecommerce/src/js/ecommerce/ecommerce';
import '../../mdb-ecommerce/src/js/ecommerce/js.cookie';
import '../../mdb-ecommerce/src/js/ecommerce/rating';
import '../../mdb-ecommerce/src/js/vendor/addons-pro/chat';
import '../../mdb-ecommerce/src/js/vendor/addons-pro/multi-range';
import '../../mdb-ecommerce/src/js/vendor/addons-pro/simple-charts';
import '../../mdb-ecommerce/src/js/vendor/addons-pro/steppers';
import '../../mdb-ecommerce/src/js/vendor/addons-pro/timeline';
import '../../mdb-ecommerce/src/js/vendor/addons/datatables';
import '../../mdb-ecommerce/src/js/vendor/addons/datatables-select';
import '../../mdb-ecommerce/src/js/vendor/addons/datatables-select2';
import '../../mdb-ecommerce/src/js/vendor/addons/datatables2';
import '../../mdb-ecommerce/src/js/vendor/addons/imagesloaded.pkgd.min';
import '../../mdb-ecommerce/src/js/vendor/addons/jquery.zmd.hierarchical-display';
import '../../mdb-ecommerce/src/js/vendor/addons/masonry.pkgd.min';
import '../../mdb-ecommerce/src/js/vendor/addons/rating';
import '../../mdb-ecommerce/src/js/vendor/free/chart';
// import '../../mdb-ecommerce/src/js/vendor/free/chartjs-datalabels';
import '../../mdb-ecommerce/src/js/vendor/free/enhanced-modals';
import '../../mdb-ecommerce/src/js/vendor/free/jquery.easing';
import '../../mdb-ecommerce/src/js/vendor/free/velocity';
import '../../mdb-ecommerce/src/js/vendor/free/waves';
import '../../mdb-ecommerce/src/js/vendor/pro/jarallax';
import '../../mdb-ecommerce/src/js/vendor/pro/jarallax-video';
import '../../mdb-ecommerce/src/js/vendor/pro/jquery.sticky';
import '../../mdb-ecommerce/src/js/vendor/pro/lightbox';
import '../../mdb-ecommerce/src/js/vendor/pro/ofi';
import '../../mdb-ecommerce/src/js/vendor/pro/picker';
import '../../mdb-ecommerce/src/js/vendor/pro/picker-date';
import '../../mdb-ecommerce/src/js/vendor/pro/picker-date-time';
import '../../mdb-ecommerce/src/js/vendor/pro/picker-time';
import '../../mdb-ecommerce/src/js/vendor/pro/vector-map';
import '../../mdb-ecommerce/src/js/vendor/pro/vector-map-world-mill';
import '../../mdb-ecommerce/js/modules/megamenu.min';


global.bsCustomFileInput = require('../../mdb-ecommerce/src/js/vendor/free/bs-custom-file-input');
window.toastr = require('../../mdb-ecommerce/src/js/vendor/pro/toastr');
