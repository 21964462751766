import { Modal } from 'bootstrap';

/**
 * Example for usage:
 * <a class="btn btn-primary"
 *   data-bsc-href="#"
 *   data-bsc-toggle="confirm"
 *   data-bsc-type="[success|warning|danger|info]"
 *   data-bsc-custom-class="modal-successful"
 *   data-bsc-message="Lorem Ipsum"
 * >
 *   <i class="fa fa-share"></i>
 * </a>
 */

export class BootstrapConfirm {
  PREFIX = 'bsc';
  DEFAULT_TEMPLATE = `
    <div class="modal fade {{ customClass }}" id="custom-confirm-modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header text-{{ messageType }}">
                    <span id="mySmallModalLabel" class="modal-title">{{ title }}</span>
                    <button type="button" class="close" data-dismiss="modal" aria-label="{{ abortLabel }}">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{ message }}
                </div>
                <div class="modal-footer btn-group p-0">
                    <button class="btn btn-link fs-6 text-decoration-none col-md-6 col-12 m-0 rounded-0 rounded-bottom" data-dismiss="modal">{{ abortLabel }}</button>
                    <button class="btn btn-{{ messageType }} fs-6 text-decoration-none col-md-6 col-12 m-0 rounded-0 rounded-bottom" data-trigger="confirm">{{ confirmLabel }}</button>
                </div>
            </div>
        </div>
    </div>
  `;

  constructor (element) {
    if (element) {
      this._setData(element);
      this._init();
    }
  }

  static getInstance(element) {
    let instance = new BootstrapConfirm();
    instance._setData(element);

    if (!element.classList.contains('bsc-instantiated')) {
      instance._init();
    }

    return instance;
  }

  _setData(element) {
    this.element = element;
    this.nodeName = element.nodeName.toLowerCase();
    this.type = element.type;
    this.messageType = element.getAttribute('data-' + this.PREFIX + '-type') ?? 'info';
    this.message = element.getAttribute('data-' + this.PREFIX + '-message') ?? '';
    this.title = element.getAttribute('data-' + this.PREFIX + '-title') ?? '';
    this.form = element.getAttribute('data-' + this.PREFIX + '-form') ?? '';
    this.href = element.getAttribute('data-' + this.PREFIX + '-href') ?? '#';
    this.template = element.getAttribute('data-' + this.PREFIX + '-template') ?? this.DEFAULT_TEMPLATE;
    this.customClass = element.getAttribute('data-' + this.PREFIX + '-custom-class') ?? '';
    this.abortLabel = element.getAttribute('data-' + this.PREFIX + '-abort-label') ?? 'Abbrechen';
    this.confirmLabel = element.getAttribute('data-' + this.PREFIX + '-confirm-label') ?? 'Ok';
  }

  _renderTemplate(settings = {}, template = null) {
    if (Object.keys(settings).length === 0 && settings.constructor === Object) {
      settings = {
        'messageType': this.messageType,
        'message': this.message,
        'title': this.title,
        'abortLabel': this.abortLabel,
        'confirmLabel': this.confirmLabel,
        'customClass': this.customClass,
      };
    }

    let templateString = template ? template : this.template;

    for (let option in settings) {
      if (typeof (settings[option]) === 'string') {
        let rgx = new RegExp('\{\{ ' + option.trim() + ' \}\}', 'g');
        templateString = templateString.replace(rgx, settings[option]);
      }
    }

    return templateString.trim();
  }

  _init() {
    // just add the event listener if the element is not disabled
    if (this._isDisabled()) { return; }

    let modal = document.createElement('div');
    modal.innerHTML = this._renderTemplate();
    modal = modal.firstChild;
    let handler;
    this.element.classList.add('bsc-instantiated');

    if (this.nodeName === 'a') {
      handler = () => {
        $(modal).modal('hide');
        // togglePageLoader(true);

        window.location.href = this.href;
      };
    } else if (this.type === 'submit') {
      let form = this.element.closest('form');
      if (!form) {
        form = document.querySelector(this.form);
      }

      form.addEventListener('submit', function (event) {
        event.preventDefault();
      });

      handler = () => {
        $(modal).modal('hide');
        // togglePageLoader(true);

        form.submit();
      };
    } else {
      handler = () => {};
    }

    modal.querySelectorAll("[data-trigger='confirm']").forEach(function (item) {
      item.addEventListener('click', handler);
    });

    this.element.addEventListener('click', function (event) {
      event.preventDefault();
      document.body.appendChild(modal);
      let modalInstance = new Modal(modal, {backdrop: 'static', keyboard: false});
      modalInstance.show();
    });

    modal.addEventListener('hidden.bs.modal', function (event) {
      $(this).modal('dispose');
      this.remove();
    });
  }

  _isDisabled() {
    return (this.nodeName === 'a' && this.element.classList.contains('disabled'))
      || (this.nodeName === 'button' && this.element.hasAttribute('disabled'));
  }
}
