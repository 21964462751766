import $ from 'jquery';
import qtyNumberInputTemplate from './qtyNumberInput';

const createTierPriceInputSelect = function createTierPriceInputSelect(tierPrice) {
  const selectList = document.createElement('select');
  selectList.id = 'sylius_add_to_cart_cartItem_quantity';
  selectList.name = 'sylius_add_to_cart[cartItem][quantity]';
  selectList.required = true;
  selectList.classList.add('mdb-select');
  selectList.classList.add('md-form');

  for (let i = 0; i < tierPrice.length; i += 1) {
    const option = document.createElement('option');
    option.value = $(tierPrice[i]).attr('data-quantity');
    option.text = `${$(tierPrice[i]).attr('data-quantity')} Stk. - ${$(tierPrice[i]).attr('data-total-value')} (${$(tierPrice[i]).attr('data-single-value')}/Stk.)`;
    selectList.appendChild(option);
  }

  $('#quantity-input').html('');
  document.getElementById('quantity-input').appendChild(selectList);
  $(selectList).materialSelect({ visibleOptions: 10 });
};

const handleProductOptionsInitTierPrice = function handleProductOptionsInitTierPrice() {
  let selector = '';
  const dataOptions = $('#sylius-product-adding-to-cart select[data-option]');

  dataOptions.each((index, element) => {
    const select = $(element);
    const option = select.find('option:selected').val();
    selector += `[data-${select.attr('data-option')}="${option}"]`;
  });

  let tierPrices = $('#tier_prices');
  const tierPrice = tierPrices.find(selector);
  let quantityInput = $('#quantity-input');
  let priceTag = $('#product-price');

  if (dataOptions.length === 0 && tierPrices.length === 1) {
    createTierPriceInputSelect(tierPrices.children().first().children());
    if (!quantityInput.closest('table').hasClass('d-none')) {
      priceTag.addClass('d-none');
    }
  } else if (tierPrice.length > 0) {
    createTierPriceInputSelect(tierPrice.children());
    if (!quantityInput.closest('table').hasClass('d-none')) {
      priceTag.addClass('d-none');
    }
  } else {
    quantityInput.html(qtyNumberInputTemplate);
    if (!quantityInput.closest('table').hasClass('d-none')) {
      priceTag.removeClass('d-none');
    }
  }
};

const handleProductOptionsChangeTierPrice = function handleProductOptionsChangeTierPrice() {
  $('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', () => {
    handleProductOptionsInitTierPrice();
  });
};

const handleProductVariantsInitTierPrice = function handleProductVariantsInitTierPrice(variant) {
  let selector = '';
  const dataAttributes = variant.parents('tr').children().first().data();

  $.each(dataAttributes, function (index, value) {
    selector += `[data-${index}="${value}"]`;
  });

  const tierPrice = $('#tier_prices').find(selector);

  if (dataAttributes.length === 0 && $('#tier_prices').length === 1) {
    createTierPriceInputSelect($('#tier_prices').children().first().children());
    $('#product-price').addClass('d-none');
  } else if (tierPrice.length > 0) {
    createTierPriceInputSelect(tierPrice.children());
    $('#product-price').addClass('d-none');
  } else {
    $('#quantity-input').html(qtyNumberInputTemplate);
    $('#product-price').removeClass('d-none');
  }
};

const handleProductVariantsChangeTierPrice = function handleProductVariantsChangeTierPrice() {
  $('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', (event) => {
    handleProductVariantsInitTierPrice($(event.currentTarget));
  });
};

$.fn.extend({
  variantTierPrices() {
    if ($('#sylius-variants-pricing').length > 0) {
      handleProductOptionsInitTierPrice();
      handleProductOptionsChangeTierPrice();
    } else if ($('#sylius-product-variants').length > 0) {
      handleProductVariantsInitTierPrice($('input[checked="checked"]'));
      handleProductVariantsChangeTierPrice();
    }
  },
});
