document.addEventListener("DOMContentLoaded", function () {
  var cookieConsent = document.querySelector('.ch-cookie-consent');
  var cookieConsentForm = document.querySelector('.ch-cookie-consent__form');
  var cookieConsentFormBtn = document.querySelectorAll('.ch-cookie-consent__btn');
  var cookieConsentCategoryDetails = document.querySelector('.ch-cookie-consent__category-group');
  var cookieConsentCategoryDetailsToggle = document.querySelector('.ch-cookie-consent__toggle-details');
  var cookieConsentStandalone = document.querySelector('.ch-cookie-consent--standalone');
  var cookieConsentCookieInfoDetailsToggle = document.querySelectorAll('.ch-cookie-consent__toggle-cookie-information');
  var cookieConsentCookieInfoDetails = document.querySelectorAll('.ch-cookie-consent__category-description-details');

  if (cookieConsentForm) {
    // Submit form via ajax
    cookieConsentFormBtn.forEach(function (btn) {
      btn.addEventListener('click', function (event) {
        event.preventDefault();
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            if (cookieConsentStandalone) {
              window.location.assign("/");
            } else {
              cookieConsent.classList.add('d-none');
            }
          }
        };
        xhr.open('GET', (cookieConsentForm.action).slice(0, cookieConsentForm.action.lastIndexOf('#')) + '?' + serializeForm(cookieConsentForm, event.target));
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send();
      }, false);
    });
  }

  // main toggle
  if (!cookieConsentStandalone) {
    if (cookieConsentCategoryDetailsToggle) {
      cookieConsentCategoryDetailsToggle.querySelectorAll('a').forEach(function (toggleButton) {
        toggleButton.addEventListener('click', function () {
          var detailsIsHidden = !cookieConsentCategoryDetails.classList.contains('d-block');
          cookieConsentCategoryDetails.classList.add(detailsIsHidden ? 'd-block' : 'd-none');
          cookieConsentCategoryDetails.classList.remove(!detailsIsHidden ? 'd-block' : 'd-none');
          cookieConsentCategoryDetailsToggle.querySelector('.ch-cookie-consent__toggle-details-hide').classList.add(detailsIsHidden ? 'd-block' : 'd-none');
          cookieConsentCategoryDetailsToggle.querySelector('.ch-cookie-consent__toggle-details-hide').classList.remove(!detailsIsHidden ? 'd-block' : 'd-none');
          cookieConsentCategoryDetailsToggle.querySelector('.ch-cookie-consent__toggle-details-show').classList.add(detailsIsHidden ? 'd-none' : 'd-block');
          cookieConsentCategoryDetailsToggle.querySelector('.ch-cookie-consent__toggle-details-show').classList.remove(!detailsIsHidden ? 'd-none' : 'd-block');
          cookieConsentCookieInfoDetails.forEach(function (container) {
            container.style.display = 'none';
            document.querySelectorAll('.ch-cookie-consent__toggle-cookie-information').forEach(function (el) {
              el.querySelector('.ch-cookie-consent__toggle-cookie-information-hide').classList.add('d-none');
              el.querySelector('.ch-cookie-consent__toggle-cookie-information-hide').classList.remove('d-block');
              el.querySelector('.ch-cookie-consent__toggle-cookie-information-show').classList.add('d-block');
              el.querySelector('.ch-cookie-consent__toggle-cookie-information-show').classList.remove('d-none');
            });
          });
        });
      });
    }
  }
});

function serializeForm(form, clickedButton) {
  var serialized = [];
  for (var i = 0; i < form.elements.length; i++) {
    var field = form.elements[i];

    if ((field.type !== 'checkbox' && field.type !== 'radio' && field.type !== 'submit') || field.checked) {
      if (clickedButton.getAttribute('name').includes('use_all_cookies') && !field.name.includes('_token')) {
        serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent('true'));
      } else {
        serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
      }
    }
  }

  if (clickedButton.getAttribute('name').includes('use_all_cookies') && !form.querySelector('[name="cookie_consent[use_only_functional_cookies]"]')) {
    serialized.push(encodeURIComponent('cookie_consent[save]') + "=");
  } else {
    serialized.push(encodeURIComponent(clickedButton.getAttribute('name')) + "=");
  }
  return serialized.join('&');
}
